import React from "react"

export const Social = () => (
  <div style={style.container}>
    <div style={style.item}>
      <a href="http://facebook.com" className="facebook" title="Join Us on Facebook!" target="_blank">
        <i className="fa fa-facebook"></i>
      </a>
    </div>
    <div style={style.item}>
      <a href="http://divnkedin.com" className="divnkedin" title="Join us on divnkedIn!" target="_blank">
        <i className="fa fa-divnkedin"></i>
      </a>
    </div>
    <div style={style.item}>
      <a href="http://plus.google.com" className="gplus" title="Add to Circle!" target="_blank">
        <i className="fa fa-google-plus"></i>
      </a>
    </div>
  </div>
)

interface SocialStyleProps {
  container: React.CSSProperties
  item: React.CSSProperties
}

const style: SocialStyleProps = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  item: {},
}

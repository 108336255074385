export interface EndeavourTheme {
  primaryColor: string
  secondaryColor: string
  textColor: string
  socialColor: string
  primaryColorInverted: string
  secondaryColorInverted: string
  textColorInverted: string
}

export const useEndeavourTheme = (): EndeavourTheme => ({
  primaryColor: "#2789cf",
  secondaryColor: "#3e9ed6",
  textColor: "#000000",
  socialColor: "#666666",
  primaryColorInverted: "#FFFFFF",
  secondaryColorInverted: "#FFFFFF",
  textColorInverted: "#FFFFFF",
})

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./carousel.css"
import { EndeavourTheme, useEndeavourTheme, combineStyle } from "../../hooks-and-hocs"
import BackgroundImage from "gatsby-background-image"

export const Carousel = () => {
  const styled = style(useEndeavourTheme())
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner_img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1078, maxHeight: 319) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag={"div"}
      style={{ backgroundColor: "#000000" }}
      // className={"carousel"}
      fluid={data.placeholderImage.childImageSharp.fluid}
    >
      <div className={"content"} style={styled.container}>
        <h1 className={"title"} style={styled.text}>
          Quality Means, Endeavour Screens
        </h1>
        <h3 className={"subtitle"} style={combineStyle(styled.text, styled.subtitle)}>
          Over 30 years of experience in manufacturing and installing security doors and screens.
        </h3>
        <Link className={"button"} to="/about" style={styled.button}>
          About Us
        </Link>
      </div>
    </BackgroundImage>
  )
}

interface CarouselStyleProps {
  container: React.CSSProperties
  text: React.CSSProperties
  subtitle: React.CSSProperties
  button: React.CSSProperties
}

const style = (theme: EndeavourTheme): CarouselStyleProps => ({
  container: {
    padding: "50px 20px",
  },
  subtitle: {
    fontWeight: 500,
    textAlign: "center",
    maxWidth: "600px",
    margin: "20px 0px",
  },
  button: {
    color: theme.secondaryColorInverted || "#FFFFFF",
    borderColor: theme.secondaryColorInverted || "#FFFFFF",
    marginTop: "10px",
  },
  text: { color: theme.textColorInverted, textShadow: "rgba(0, 0, 0, 0.6) 0px 0px 5px" },
})

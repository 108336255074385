import { gql } from 'apollo-boost'

export const createQuoteMutation = gql`
  mutation createQuote($input:CreateQuoteInput!) {
    createQuote(input:$input) {
      id
      customer {
        id
      }
      status
    }
  }
`
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export const LogoInverted: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-inverted.png" }) {
        childImageSharp {
          fixed(width: 257, height: 45) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      style={{ ...style, width: "257px", height: "45px", margin: "25px 0px" }}
      alt={"Endeavour Screens Inverted Logo"}
    />
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Header } from './header'
import { Footer } from './footer'
import './layout.css'

export const Layout: React.FC<{}> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          theme {
            secondaryColor
            primaryColorInverted
          }
        }
      }
      file(relativePath: { eq: "footer_bg.jpg" }) {
        childImageSharp {
          fixed(width: 45, height: 44) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        minHeight: '100vh',
        overflowX: 'hidden',
      }}
    >
      <Header />
      <div style={{ flex: 1 }}>
        <main>{children}</main>
      </div>
      <Footer
        data={data}
        style={{
          flex: 'none',
          backgroundColor: data.site.siteMetadata.theme.secondaryColor,
          color: data.site.siteMetadata.theme.primaryColorInverted,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 0px',
        }}
      />
    </div>
  )
}

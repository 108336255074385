import React from "react"
import { Link } from "gatsby"
import { EndeavourTheme, useEndeavourTheme } from "../hooks-and-hocs"

interface NavigationProps {
  collapsed?: boolean
  inverted?: boolean
}

export const Navigation = ({ collapsed, inverted }: NavigationProps) => {
  const styled = style(useEndeavourTheme(), inverted)
  return (
    <nav style={styled.container}>
      <Link to="/" className={"navigation-item"} style={styled.navItem}>
        Home
      </Link>
      <Link to="/crimsafe" className={"navigation-item"} style={styled.navItem}>
        Crimsafe
      </Link>
      <Link to="/quote" className={"navigation-item"} style={styled.navItem}>
        Quote
      </Link>
      <Link to="/about" className={"navigation-item"} style={styled.navItem}>
        About Us
      </Link>
    </nav>
  )
}

interface HeaderStyleProps {
  container: React.CSSProperties
  navItem: React.CSSProperties
}

const style = (theme: EndeavourTheme, inverted?: boolean): HeaderStyleProps => ({
  container: {},
  navItem: { color: inverted ? theme.primaryColorInverted : theme.socialColor },
})

import React from "react"
import { Link } from "gatsby"
import { Logo, CrimsafeLogoAuthorized } from "./images"
import { Navigation } from "./navigation"
import { useEndeavourTheme, EndeavourTheme } from "../hooks-and-hocs"
import "./header.css"

export const Header = () => {
  const styled = style(useEndeavourTheme())

  return (
    <header style={styled.header}>
      <div className={"container"} style={styled.container}>
        <div className="row" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Link to="/">
            <Logo />
          </Link>
          <CrimsafeLogoAuthorized />
        </div>
        <Navigation collapsed={false} />
      </div>
    </header>
  )
}

interface HeaderStyleProps {
  header: React.CSSProperties
  container: React.CSSProperties
}

const style = (theme: EndeavourTheme): HeaderStyleProps => ({
  header: { borderTopColor: theme.secondaryColor },
  container: {},
})

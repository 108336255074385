import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface CrimsafeLogoProps {
  style?: React.CSSProperties
}

export const CrimsafeLogo: React.FC<CrimsafeLogoProps> = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "crimsafe_logo.jpg" }) {
        childImageSharp {
          fixed(width: 150, height: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      fadeIn={true}
      style={{ ...style, width: "150px", height: "80px", display: "inline-block" }}
      alt={"Crimsafe Logo"}
    />
  )
}

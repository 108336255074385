import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface StoreImageProps {
  style?: React.CSSProperties
}

export const StoreImage: React.FC<StoreImageProps> = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "store-front.jpg" }) {
        childImageSharp {
          fixed(width: 490, height: 219) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      style={{ ...style, width: "100%", height: "219px", display: "inline-block" }}
      alt={"Endeavour Screens Factory"}
    />
  )
}

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export const Logo: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fixed(width: 315, height: 39) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      style={{ ...style, width: "315px", height: "39px", margin: "25px 0px" }}
      alt={"Endeavour Screens Logo"}
    />
  )
}

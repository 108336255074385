import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { combineStyle } from "../../hooks-and-hocs"
import Img from "gatsby-image"

interface CrimsafeLogoAuthorizedProps {
  style?: React.CSSProperties
}

export const CrimsafeLogoAuthorized: React.FC<CrimsafeLogoAuthorizedProps> = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "crimsafe_logo_authorized.jpg" }) {
        childImageSharp {
          fixed(width: 150, height: 54) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      fadeIn={true}
      className={"crimsafe-logo"}
      style={combineStyle(style, { width: "150px", height: "54px" })}
      alt={"Authorized Crimsafe Dealer Logo"}
    />
  )
}

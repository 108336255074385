import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import BackgroundImage from 'gatsby-background-image'

import { createQuoteMutation } from '../graph'
import { LogoInverted } from './images'
import { Navigation } from './navigation'
import { Social } from './social'
import { combineStyle } from '../hooks-and-hocs'

export const Footer: React.FC<{ data: any; style: React.CSSProperties }> = props => {
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone, setPhone] = useState<string>()

  const [createQuote, { data, error }] = useMutation<{ createQuote: { id: string; customer?: any } }>(createQuoteMutation, { errorPolicy: 'all' })

  const handleFormSubmission = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    createQuote({ variables: { input: { name, email, phone } } })
  }

  return (
    <footer>
      <div style={combineStyle(props.style)}>
        <h2 style={{ textAlign: 'center', fontWeight: 300 }}>Speak with an Expert</h2>
        <h4 style={{ textAlign: 'center', fontWeight: 500, fontSize: '1.2rem', margin: '10px 20px', color: '#FFFFFF' }}>
          Request a free quote by completing the form below.
        </h4>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            maxWidth: '500px',
            width: '100%',
          }}
          onSubmit={handleFormSubmission}
        >
          {data && data.createQuote && <div>Created: {data.createQuote.id}</div>}
          {error && (
            <div>
              Oops,{' '}
              {error.graphQLErrors.map(err => (
                <span>{err.message}</span>
              ))}
            </div>
          )}
          {data && !data.createQuote && <div>Couldn't create Quote</div>}
          <div style={styles.fieldRow}>
            <input name={'name'} type={'text'} placeholder={'Full name'} style={styles.fieldCell} onChange={e => setName(e.target.value)} />
            <input name={'email'} type={'email'} placeholder={'Email'} style={styles.fieldCell} onChange={e => setEmail(e.target.value)} />
          </div>
          <div style={styles.fieldRow}>
            <input
              name={'phone'}
              type={'number'}
              placeholder={'Home Phone (optional'}
              style={styles.fieldCell}
              onChange={e => setPhone(e.target.value)}
            />
            <input
              name={'mobile'}
              type={'number'}
              placeholder={'Mobile (optional)'}
              style={styles.fieldCell}
              onChange={e => setPhone(e.target.value)}
            />
          </div>
          <div style={styles.fieldRow}>
            <textarea placeholder={'Comments (optional)'} style={styles.fieldCell} />
          </div>
          <div style={styles.fieldRow}>
            <button className={'button'} type={'submit'} name={'submit'}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <BackgroundImage
        Tag={'footer'}
        fluid={props.data.file.childImageSharp.fixed}
        style={{ backgroundRepeat: 'repeat', backgroundPosition: 'center', backgroundSize: 'initial', backgroundColor: '#333333' }}
        backgroundColor={'#333333'}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '960px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
          }}
        >
          <div style={{ flex: 1 }}>
            <LogoInverted />
            <Navigation inverted={true} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'flex-end',
              flex: 1,
            }}
          >
            <Social />
            <div style={{ color: props.data.site.siteMetadata.theme.primaryColorInverted }}>07 5539 2389</div>
            <div style={{ color: props.data.site.siteMetadata.theme.primaryColorInverted }}>info@endeavourscreens.com.au</div>
          </div>
        </div>
      </BackgroundImage>
    </footer>
  )
}

interface FooterStyles {
  fieldRow: React.CSSProperties
  fieldCell: React.CSSProperties
}

const styles: FooterStyles = {
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    alignItems: 'stretch',
    padding: '5px',
  },
  fieldCell: { flex: 1 },
}
